<template>
  <div class="col-12 card card-body">
    <div class="col-12 table-responsive">
        <div class="col-12 text-center g" v-if="loading">
            <img src="/files/loading.svg" alt="">
        </div>
        <div class="g"><input type="text" class="form-control form-control-lg" @change="getArchive()" v-model="q" placeholder="ابحث هنا..."></div>
        <table class="table table-sm table-hover table-bordered">
            <thead>
                <th>
                    ID
                </th>
                <th>
                   اسم المدرسة
                </th>
                <th>
                    اسم صاحب الحساب
                </th>
                <th>
                    تاريخ الانضمام
                </th>
                <th>
                    تاريخ الانتهاء
                </th>
            </thead>
            <tbody>
                <tr v-for="message in messages" :key="message._id">
                    <td>
                        <a href="javascript:;" @click="$router.push('/school/' + message.school_id)">{{ message.school_id }}</a>
                    </td>
                    <td>
                        <span v-if="message.m" class="text-danger">[مشرف]</span>
                        {{ message.school_name }}
                    </td>
                    <td>
                        {{ message.admin_name }}
                    </td>
                    <td>
                        {{ message.date }}
                    </td>
                    <td>
                        {{ message.exdate }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-12">
        <button class="btn btn-danger" @click="page--; getArchive()" v-if=" page > 0 && !loading">الصفحة السابقة <i class="fa fa-arrow-right"></i></button>&nbsp;
        <button class="btn btn-primary" @click="page++; getArchive()" v-if="messages.length > 98 && !loading">الصفحة التالية <i class="fa fa-arrow-left"></i></button>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            messages: [],
            page: 0,
            loading: false,
            q: null
        }
    },
    created(){
        this.getArchive();
    },
    methods: {
        getArchive(){
            var g = this;
            this.loading = true;
            $.post(api + '/admin/schools', {
                jwt: localStorage.getItem("jwt"),
                page: this.page,
                search: this.q
            })
            .then(function(response){
                g.loading = false
                if(response.status == 100){
                    g.messages = JSON.parse(JSON.stringify(response.response)).map(function(a){
                        a.view_message = false
                        return a
                    })
                }else{
                    g.messages = []
                    alert("حدث خطأ.")
                }
            })
            .fail(function(){
                g.loading = false
                g.messages = []
                alert("حدث خطأ")
            })
        }
    }
}
</script>

<style>
th , td{
    white-space: nowrap !important;
}
</style>